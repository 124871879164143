import axiosInstance from '@/utils/requests'

export function upload(file){
    return axiosInstance({
        method: 'POST',
        url: '/files/upload',
        params: file,
        headers:{'Content-Type':"multipart/form-data"}
    })
}

// 广告接口
export function addvideoAD(data){
    return axiosInstance({
        method: 'POST',
        url: '/videoAD/addAD',
        data: data,
        headers:{'Content-Type':"application/json"}
    })
}
export function getContentByPosition(position){
    return axiosInstance({
        method: 'GET',
        url: '/videoAD/getContentByPosition/'+position,
    })
}
export function changevideoADStatus(data){
    return axiosInstance({
        method: 'POST',
        url: '/videoAD/changeStatus',
        data: data,
        headers:{'Content-Type':"application/json"}
        
    })
}

export function editvideoAD(data){
    return axiosInstance({
        method: 'POST',
        url: '/videoAD/edit',
        data: data,
        headers:{'Content-Type':"application/json"}
    })
}

export function videoADDetails(id){
    return axiosInstance({
        method: 'GET',
        url: '/videoAD/details'+id,
    })
}


export function deletevideoAD(id){
    return axiosInstance({
        method: 'POST',
        url: '/videoAD/delete/'+id
    })
}


// 视频接口
export function getPublishedVideoList(){
    return axiosInstance({
        method: 'GET',
        url: '/video/getAllPublished',
    })
}
export function addVideo(video){
    return axiosInstance({
        method: 'POST',
        url: '/video/addVideo',
        data: video,
        headers:{'Content-Type':"application/json"}
    })
}

export function changeVideoStatus(video){
    return axiosInstance({
        method: 'POST',
        url: '/video/changeStatus',
        data: video,
        headers:{'Content-Type':"application/json"}

    })
}

export function editVideo(video){
    return axiosInstance({
        method: 'POST',
        url: '/video/edit',
        data: video,
        headers:{'Content-Type':"application/json"}
    })
}

export function videoDetails(id){
    return axiosInstance({
        method: 'GET',
        url: '/video/details/'+id
    })
}

export function videoLists(params){
    return axiosInstance({
        method: 'GET',
        url: '/video/lists',
        params:params
    })
}

export function deleteVideo(id){
    return axiosInstance({
        method: 'POST',
        url: '/video/delete/'+id
    })
}