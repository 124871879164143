<template>
  <el-row class="videoContainer">
    <el-col :span="24" v-for="list in videoList" :key="list.id" class="cover-item-container">
      <div class="cover-item">
        <el-image :src="staticHost + '/' + list.cover" fit="fill" :alt="list.title" class="cover"></el-image>
        <div class="overlay" @click="jumpToDetails(list.id)">
          <P class="video-title">{{ list.title }}</P>
        </div>
      </div>
    </el-col>
    <el-backtop target=".videoContainer" :bottom="20">
      <div class="backTop">
        ▲
      </div>
    </el-backtop>
  </el-row>
</template>

<script>
import { staticHost } from '@/utils/requests';
import { getPublishedVideoList } from '@/utils/api/course';

export default {
  name: 'VideoList',
  data() {
    return {
      videoList: [],
      dialogAD: { content: "ssssaaaaa" },
      staticHost: staticHost,
    };
  },
  methods: {
    jumpToDetails(id) {
      this.$router.push({ name: "videoDetails", params: { id: id } })
    },
    getPublishedVideoList() {
      getPublishedVideoList().then(res => {
        if (res.code === 200) {
          this.videoList = res.data;
          this.loading = false;
        } else {
          this.$message.error('无法获取视频数据');
        }
      }).catch(err => {
        this.$message.error(err);
      });
    }
  },
  created() {
    this.getPublishedVideoList();
  }
};  
</script>

<style scoped>
.videoContainer {
  overflow-y: scroll;
  height: 100%;
}

/* 自定义滚动条样式 */
.videoContainer::-webkit-scrollbar {
  width: 5px;
  /* 设置滚动条宽度 */
}

.videoContainer::-webkit-scrollbar-thumb {
  background-color: rgb(124, 124, 124);
  /* 设置滚动条颜色 */
  border-radius: 4px;
  /* 设置滚动条圆角 */
}

.videoContainer::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
  /* 设置滚动条轨道颜色 */
}

.cover-item {
  margin: auto;
  width: 62rem;
  transition: transform 0.3s ease;
  height: 15.5rem;
  position: relative;
  margin-bottom: 4.375rem;
}


.cover {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  color: white;
}

.video-title {
  margin: 0;
  font-size: 2rem;
  text-align: center;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 交互效果：当鼠标悬停在 .cover-container 上时，显示 .overlay */
.overlay:hover {
  color: #2f85d6;
}

.cover-item:hover {
  transform: scale(1.02);
}

.backTop {
  height: 100%;
  width: 100%;
  background-color: #f2f5f6;
  box-shadow: 0 0 0.5rem rgba(216, 26, 26, 0.12);
  border-radius: 50%;
  text-align: center;
  font-size: medium;
  line-height: 40px;
  color: #1989fa;
}

@media (min-width: 0px) and (max-width: 320px) {
  .video-title{
    font-size: 3rem;
  }

}
@media (min-width: 321px) and (max-width: 425px) {
  .video-title{
    font-size: 3rem;
  }

}
@media (min-width: 426px) and (max-width: 768px) {
  .video-title{
    font-size: 3rem;
  }

}
</style>