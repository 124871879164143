import Vue from 'vue';  
import VueRouter from 'vue-router';  
import videoDetails from '@/components/content/videoDetails.vue'; // 示例组件  
import videoList from '@/components/videoList.vue'; // 示例组件  

Vue.use(VueRouter);  
  
const routes = [  
  {  
    path: '/',  
    name: 'videoList',  
    component: videoList  
  },
  {  
    path: '/videoDetails/:id',  
    name: 'videoDetails',  
    component: videoDetails  ,
    props: true
  }  
];  
  
const router = new VueRouter({  
  mode: 'history', // 使用 HTML5 历史模式  
  routes  
});  
  
export default router;