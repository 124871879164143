import axios from 'axios';  
export const staticHost = 'http://8.134.55.213:8081/static'
export const baseURL = "/api"

// 创建一个Axios实例  
const axiosInstance = axios.create({  
  baseURL: baseURL, // API的基础URL  
  timeout: 5000, // 请求超时时间  
  // 可以在这里设置默认的headers，但通常我们会在每个请求中动态设置headers  
  // headers: { 'Authorization': 'Bearer your-token' }  
  withCredentials:true
});  
  
// 添加请求拦截器（可选）  
axiosInstance.interceptors.request.use(  
  config => {  
    // 在发送请求之前做些什么，比如添加认证头  
    // if (store.getters.token) {  
    //   config.headers['Authorization'] = `Bearer ${store.getters.token}`;  
    // }  
    return config;  
  },  
  error => {  
    // 对请求错误做些什么  
    return Promise.reject(error);  
  }  
);  
  
// 添加响应拦截器（可选）  
axiosInstance.interceptors.response.use(  
  response => {  
    // 对响应数据做点什么，比如统一处理错误码  
    if(response.data.code === 200){
      return response.data; 
    }else{
      return Promise.reject(response.data.message); 
    }
  },  
  error => {  
    // 对响应错误做点什么，比如显示错误消息  
    return Promise.reject(error);  
  }  
);  
  
export default axiosInstance;