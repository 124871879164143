<template>
    <el-main class="content">
        <div style="width: 62rem;margin: auto;">
            <!-- 顶部图片广告位 -->
            <el-row v-if="topImages.length > 0" class="image">
                <el-col v-for="item in topImages" :key="item.id">
                    <a :href="item.jumpUrl" target="_blank">
                        <el-image :src="`${staticHost}/${item.content}`" lazy class="imageAD"></el-image>
                    </a>
                </el-col>
            </el-row>
            <hr v-if="topImages.length > 0">
            <!-- 文字广告位 -->
            <el-row v-if="topTexts.length > 0" :gutter="2">
                <el-col v-for="text in topTexts" :key="text.id" class="text">
                    <a :href="text.jumpUrl" target="_blank">
                        {{ text.content }}
                    </a>
                </el-col>
            </el-row>
            <hr v-if="topTexts.length > 0">
            <!-- 视频播放轮播图 -->
            <el-row>
                <el-col class="video">
                    <video :src="videoUrl" controls class="responsive-video"></video>
                </el-col>
            </el-row>
            <hr>
            <!-- 底部图片广告位 -->
            <el-row v-if="footImages.length > 0">
                <el-col v-for="item in footImages" :key="item.id">
                    <a :href="item.jumpUrl" target="_blank">
                        <el-image :src="`${staticHost}/${item.content}`" lazy class="imageAD"></el-image>
                    </a>
                </el-col>
            </el-row>
            <hr v-if="footImages.length > 0">
            <!-- 文字广告位 -->
            <el-row v-if="footTexts.length > 0">
                <el-col :span="5" v-for="text in footTexts" :key="text.id" class="text">
                    <a :href="text.jumpUrl" target="_blank">
                        {{ text.content }}
                    </a>
                </el-col>
            </el-row>
        </div>
        <div class="back" @click="$router.go(-1)">
            <i class="el-icon-back"></i>
        </div>
    </el-main>
</template>

<script>
import { getContentByPosition, videoDetails } from '@/utils/api/course'
import { staticHost } from '@/utils/requests';

export default {
    props: ['id'],
    name: 'VideoDetails',
    data() {
        return {
            videoData: {},
            topImages: [],
            topTexts: [],
            footImages: [],
            footTexts: [],
            staticHost: staticHost,
            videoId: this.id
        };
    },
    computed: {
        videoUrl() {
            return this.videoData.uploadType === 0 ? this.videoData.url : `${staticHost}/${this.videoData.url}`;
        }
    },
    methods: {
        async fetchADsByPosition(position) {
            try {
                const res = await getContentByPosition(position);
                if (res.code === 200) {
                    return res.data;
                } else {
                    this.$message.error(res.message);
                    return [];
                }
            } catch (err) {
                this.$message.error(err);
                return [];
            }
        },
        async fetchVideoDetails(id) {
            try {
                const res = await videoDetails(id);
                if (res.code === 200) {
                    this.videoData = res.data;
                } else {
                    this.$message.error(res.message);
                }
            } catch (err) {
                this.$message.error(err);
            }
        },
        async fetchAllData() {
            const videoId = this.videoId;

            // Fetch video details
            await this.fetchVideoDetails(videoId);

            // Fetch ads
            this.topImages = await this.fetchADsByPosition(0);
            this.topTexts = await this.fetchADsByPosition(1);
            this.footImages = await this.fetchADsByPosition(2);
            this.footTexts = await this.fetchADsByPosition(3);
        }
    },
    created() {
        this.fetchAllData();
    }
};
</script>

<style scoped>
hr {
    border: none;
    border-top: 1px solid rgb(94, 94, 94);
    width: 100%;
    margin: 2rem auto;
}

.back {
    width: 3rem;
    height: 3rem;
    background-color: #383838;
    color: aqua;
    border-radius: 50%;
    position: absolute;
    top: 1.25rem;
    display: grid;
    left: 27%;
    font-size: 2rem;
    align-content: space-evenly;
    justify-content: center;
    align-items: center;
    justify-items: center;
    z-index: 1000;

}

.back:hover {
    background-color: #222222;
}

a {
    text-decoration: none;
    color: inherit;
}

.text a {
    display: grid;
    align-content: space-evenly;
    justify-content: center;
    align-items: center;
    justify-items: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: inherit;
}

a:hover,
a:focus,
a:active {
    text-decoration: none;
    color: inherit;
}

.content {
    height: 100%;
    margin-right: 1.2rem;
    width: 100%;
    /* 确保内容宽度为800rem */
    z-index: 1;
    overflow-y: auto;
    /* 确保垂直方向上可以滚动 */
}

/* 自定义滚动条样式 */
.content::-webkit-scrollbar {
    width: 5px;
    /* 设置滚动条宽度 */
}

.content::-webkit-scrollbar-thumb {
    background-color: rgb(124, 124, 124);
    /* 设置滚动条颜色 */
    border-radius: 0.25rem;
    /* 设置滚动条圆角 */
}

.content::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);
    /* 设置滚动条轨道颜色 */
}

.imageAD {
    margin-bottom: 1.5rem;
    width: 100%;
    height: 15rem;
    object-fit: cover;
    border-radius: 0.5rem;
    /* 可选：为图片添加圆角 */
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.video {
    width: 100%;
}

.responsive-video {
    /* 视频最大宽度为800rem */
    width: 100%;
    /* 视频宽度为100%以填充父容器 */
    height: auto;
    /* 高度自适应 */
    object-fit: contain;
    /* 视频内容适应容器 */
    border-radius: 0.8rem;
    /* 可选：为视频添加圆角 */
}

.text {
    height: 5rem;
    width: 23%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    margin: 1%;
    border: 1px solid #7a7a7a;
    text-align: center;
}

.text:hover {
    background-color: rgb(31, 31, 31);
}

.el-row {
    position: relative;
    z-index: 1;
    /* 让其他内容在Swiper之上 */
}

@media (min-width: 0px) and (max-width: 320px) {
    .back {
        left: 5%;
        width: 8rem;
        height: 8rem;
        font-size: 3rem;

    }
}
@media (min-width: 321px) and (max-width: 425px) {
    .back {
        left: 5%;
        width: 8rem;
        height: 8rem;
        font-size: 3rem;

    }
}
@media (min-width: 426px) and (max-width: 768px) {
    .back {
        top: 0;
        left: 2%;
        width: 5rem;
        height: 5rem;
        font-size: 3rem;
        

    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .back {
        top: 0;
        left: 4%;
        width: 4rem;
        height: 4rem;
        

    }
}
@media (min-width: 1025px) and (max-width: 1440px) {
    .back {
        top: 0;
        left: 3.5%;
        width: 3rem;
        height: 3rem;
        

    }
}
@media (min-width: 1441px) and (max-width: 1920px) {
    .back {
        top: 0;
        left: 20%;
        width: 4rem;
        height: 4rem;
        

    }
}
</style>
