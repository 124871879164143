<template>
  <div id="app">
    <div v-for="ad in ads.data" :key="ad.id" class="dialogADContainer">
      <div class="dialogADItem" v-if="ad.isPublished == 1">
        <a :href="ad.jumpUrl" target="_blank">
          <el-image :src="`${staticHost}/${ad.content}`" class="ADContent"></el-image>
        </a>
        <div class="close-icon" @click="ad.isPublished = 0"><i class="el-icon-circle-close"></i></div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { getContentByPosition } from '@/utils/api/course'
import { staticHost } from './utils/requests';
export default {
  name: 'App',
  data() {
    return {
      ads: {},
      staticHost: staticHost
    };
  },
  methods: {
    async getAD() {
      try {
        const res = await getContentByPosition(4)
        this.ads = res
        console.log(this.ads.data)
      } catch (err) {
        console.log(err)
      }

    }
  },
  created() {
    this.getAD()
  }
};
</script>

<style scoped>
#app {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #333333;
  height: 100%;
  overflow: hidden;
  color: #fff;

}

.dialogADContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.dialogADItem {
  width: 21.875rem;
  height: 31.25rem;
  background-color: rgba(0, 0, 0, 0.7);
  position: relative;
  pointer-events: auto;
  z-index: 1000;
}

.close-icon {
  position: absolute;
  font-size: 800;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  color: #fff;
  font-size: 1.7rem;
}

.ADContent {
  width: 100%;
  height: 100%;
  pointer-events: auto;
  object-fit: cover;
}

@media (max-width: 565px) {
  .dialogADItem {
    width: 51.875rem;
    height: 61.25rem;
  }
  .close-icon {
  font-size: 4rem;
}
}
@media (min-width: 566px) and (max-width: 768px) {
  .dialogADItem {
    width: 51.875rem;
    height: 61.25rem;
  }
  .close-icon {
  font-size: 3rem;
}
}
@media (min-width: 769px) and (max-width: 1024px) {
  .dialogADItem {
    width: 41.875rem;
    height: 51.25rem;
  }
  .close-icon {
  font-size: 2rem;
}
}
@media (min-width: 1025px) {
  .dialogADItem {
    width: 21.875rem;
    height: 31.25rem;
  }
  .close-icon {
  font-size: 2rem;
}
}
</style>
